export const palette = {
  white: '#FFFFFF',
  black: '#000000',

  gray25: '#2c3235',
  gray15: '#181b1f', //'#202226',
  gray10: '#0a0b0c', // old '#141619',
  gray05: '#000000', // old '#0b0c0e',

  // new from figma,
  darkLayer0: '#000000',
  darkLayer1: '#0a0a0a',
  darkLayer2: '#202226', // figma used #34343B but a bit too bright
  darkBorder1: '#222426',
  darkBorder2: '#686868',

  // Dashboard bg / layer 0 (light theme)
  gray90: '#FFFFFF',
  // Card bg / layer 1
  gray100: '#FFFFFF',
  // divider line
  gray80: '#D0D1D3',
  // from figma
  lightBorder1: '#dce1e6',
  lightBorder2: '#919191',

  blueDarkMain: '#0d0deb', // '#4165F5',
  blueDarkText: '#1f60c4', // '#58a6ff', //'#33a2e5', // '#5790FF',
  redDarkMain: '#eb112b',
  redDarkText: '#ffd9d9',
  greenDarkMain: '#1e8b3a',
  greenDarkText: '#7dd794',
  orangeDarkMain: '#ff6500',
  orangeDarkText: '#cf5d01',

  blueLightMain: '#0d0deb',
  blueLightText: '#3c3cff',
  redLightMain: '#eb112b',
  redLightText: '#ffd9d9',
  greenLightMain: '#1e8b3a',
  greenLightText: '#7dd794',
  orangeLightMain: '#ff6500',
  orangeLightText: '#cf5d01',
};

